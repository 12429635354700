:root {
  --color-100: #fff;
  --color-150: #f3f3f3;
  --color-200: #dadada;
  --color-300: #ccc;
  --color-400: #999;
  --color-500: #777;
  --color-850: #222;
  --color-900: #111;
  --danger: #ee0055;
  --danger-base: #fff6f6;
  --primary: #634bb7;
  --primary-10: #F3F5FC;
  --primary-20: #dcd9ff;
  --primary-30: #b4b0ff;
  --primary-100: #7364d1;
  --secondary: #FF7600;
  /* */
  --primary-alpha: #6b4fd299;
  /* */
  --shadow-100: #0002;
  --shadow-500: #0004;
  --shadow-900: #0006;
}